import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    width: "100px"
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
  },
  {
    id: 32,
    name: "Exam Time",
    selector: (row) => row.meta.exam_time,
    sortable: true,
    reorder: true
  },
  {
    id: 33,
    name: "Availability",
    selector: (row) => row.meta.available_unavailable,
    sortable: true,
    reorder: true,
    width: "150px"
  },
  // {
  //   id: 34,
  //   name: "Type",
  //   selector: (row) => row.meta.exam_type,
  //   sortable: true,
  //   reorder: true
  // },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.exam_details}${row.slug}`}>View</Link>,

  }
];

export default function AllCBTExam() {
  const [Projects1, setProjects1] = React.useState([])
  const [Projects2, setProjects2] = React.useState([])
  const [Available, setAvailable] = React.useState(0)
  const [UnAvailable, setUnAvailable] = React.useState(0)
  
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [toStudentModel, setToStudentModal] = React.useState(false)
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_paid_exam)
      .then((res) => {
        setProjects2(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetProject()
  }, [])
  const GetProject = () => {
    GetAllProjects({
      per_page: 200,
      _fields: "id,title,slug,date,status,meta.exam_time,meta.available_unavailable,meta.exam_type",
      "filter[meta_query][1][key]": "exam_type",
      "filter[meta_query][1][value][0]": "Textbook",
      "filter[meta_query][1][compare]": "=",
    })
  }

  return (
    <div>
    <div className="row" style={{ padding: "30px 30px" }}>
      <div className="col-md-8 button">
        <h2>Text Book Exam</h2>
      </div>
      <div className="col-md-4" style={{ textAlign: 'right' }}>
        <a href="/">Dashboard</a> / Text Book Exam
      </div>
    </div>
    <a href={slugs.new_text_exam}>
      <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
        onClick={() => {

        }}
      >Tambh Soal Baru</div>
    </a>
    <div className="row">
      <div style={{ display: "inline", cursor: "pointer" }}>
        <div style={{ display: "inline-block", margin: "0px 5px" }}
          onClick={() => {
            setProjects2(CustomersList)
          }}
        >
          Semua ({CustomersList.length}) |
        </div>
        <div style={{ display: "inline-block", margin: "0px 5px" }}
          onClick={() => {
            setProjects2(CustomersList.filter(a => a.meta.available_unavailable == "Available"))
          }}
        >
          Tersedia ({Available}) |
        </div>
        <div style={{ display: "inline-block", margin: "0px 5px" }}
          onClick={() => {
            setProjects2(CustomersList.filter(a => a.meta.available_unavailable == "Unavailable"))
          }}
        >
          Tidak tersedia ({UnAvailable}) |
        </div>
        {Projects1.filter(a => a.meta.available_unavailable == "Unavailable").length !== 0 &&
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              for (let i = 0; i < Projects1.length; i++) {
                setReload(true)
                AdsPUTApi({
                  meta: {
                    "available_unavailable": "Available",
                  }
                }, slugs.db_slug_paid_exam, Projects1[i].id)
                  .then(res => {
                    if (i == Projects1.length - 1) {
                      setProjects1([])
                      GetProject()
                    }
                  })
              }
            }
            }
          >
            Make Available |
          </div>
        }

        {Projects1.filter(a => a.meta.available_unavailable == "Available").length !== 0 &&
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              for (let i = 0; i < Projects1.length; i++) {
                setReload(true)
                AdsPUTApi({
                  meta: {
                    "available_unavailable": "Unavailable",
                  }
                }, slugs.db_slug_paid_exam, Projects1[i].id)
                  .then(res => {
                    if (i == Projects1.length - 1) {
                      setProjects1([])
                      GetProject()
                    }
                  })
              }
            }
            }
          >
            Make Unavailable |
          </div>
        }
        {Projects1.filter(a => a.status == "publish").length !== 0 &&
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setToStudentModal(true)

            }
            }
          >
            Delete Exam |
          </div>
        }
      </div>
    </div>

    <div style={{ backgroundColor: "#fff", padding: "10px", width: "100%", margin: "10px" }}>
      <DataTable
        columns={columns}
        data={Projects2}
        defaultSortFieldId={1}
        defaultSortAsc={false}
        striped
        // sortIcon={<SortIcon />}
        pagination
        selectableRows
        onSelectedRowsChange={(r) => {
          console.log("asdasd", r)
          setProjects1(r.selectedRows)
        }}
      />
    </div>
    {toStudentModel &&
      <section className="modal" >
        <div className="row" style={{ overflow: "hidden" }}>
          <div className="col-md-3 col-1"></div>
          <div className="col-md-6 col-10  shadow bg-white">
            <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
              <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                onClick={() => {
                  setToStudentModal(false)
                }}
              >X</div>
              <div>
                <center>
                  Do you really want to Delete all the exams?<br />
                  This Process is irreversible
                  <div className="row">
                    <div className="col-3 center " />
                    <div className="col-3 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setToStudentModal(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-3 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setToStudentModal(false)
                          for (let i = 0; i < Projects1.length; i++) {
                            setReload(true)
                            AdsPUTApi({
                              status: "draft"
                            }, slugs.db_slug_paid_exam, Projects1[i].id)
                              .then(res => {
                                if (i == Projects1.length - 1) {
                                  setProjects1([])
                                  GetProject()
                                }
                              })
                          }
                        }}
                      >
                        Delete All Exams
                      </div>
                    </div>
                  </div>
                </center>
              </div>

              <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                <DataTable
                  paginationPerPage={100}
                  paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                  columns={columns}
                  data={Projects1}
                  defaultSortFieldId={1}
                  defaultSortAsc={false}
                  striped
                  // sortIcon={<SortIcon />}
                  pagination
                  selectableRows={false}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

    }
    {Reload &&
      <div className="modal" style={{ padding: "50vw" }}>
        <div className="dot-pulse"></div>
      </div>
    }
  </div>

 )
}